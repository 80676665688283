import Vue from "vue";
import Vuex from "vuex";
import { user } from "./user";
import { meta } from "./meta";
// import request from "tsl-axios";
// import createPersistedState from "vuex-persistedstate";
// import * as Cookies from "js-cookie";

Vue.use(Vuex);

const store = {
  modules: {
    user,
    meta
  },
  mutations: {},
  actions: {},
  plugins: [
    /*createPersistedState({
      reducer(state) {
        return {
          // 只储存state中的user信息
          user: {
            ...state.user,
            authMap: {
              operate: [],
              user: []
            }
          }
        };
      },
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) => Cookies.set(key, value, { expires: 1 / 24 }),
        removeItem: (key) => Cookies.remove(key)
      }
    })*/
  ]
};

const storeInstance = new Vuex.Store(store);

// request.instance.interceptors.request.use(
//   function (config) {
//     if (storeInstance.getters["user/isAuthenticated"]) {
//       config.headers.common.Authorization = storeInstance.state.user.token;
//     }
//     return config;
//   },
//   function (error) {
//     return Promise.reject(error);
//   }
// );

export default storeInstance;
