// 运行时publicPath
import "./public-path";
// 公共样式和变量
import "./assets/style/common.less";
// 网络请求插件
import "./plugin/axios";
// 全局组件和插件
import "./plugin/component";
import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import { initRouter } from "./router";
import tslI18n from "tsl-locale";
import store from "./store";

import { message } from "ant-design-vue";

if (process.env.VUE_APP_MOCK) {
  const { mockXHR } = require("../mock");
  mockXHR();
}
import "@/assets/icons/index.js";

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.prototype.$message = message;
let instance = null;

function render(props = {}) {
  const { container } = props;
  instance = new Vue({
    render: (h) => h(App),
    i18n: tslI18n("zh"),
    store,
    router: initRouter()
  }).$mount(container ? container.querySelector("#app") : "#app");
}

// 独立运行时
if (!window.__POWERED_BY_QIANKUN__) {
  render();
}

export async function bootstrap() {
  console.log("[vue] vue app bootstraped");
}

export async function mount(props) {
  console.log("[vue] props from main framework", props);
  render(props);
}

export async function unmount() {
  instance.$destroy();
  instance.$el.innerHTML = "";
  instance = null;
}
