/*
 * @Description:营销模块接口请求模拟
 * @Version: feat 1.0
 * @Date: 2021-01-20 15:13:25
 * @LastEditTime: 2021-01-28 11:06:15
 */
import { Random } from "mockjs";

export default [
  // market market-tool
  {
    url: "/api/table/demo",
    type: "get",
    response: ({ query }) => {
      let params = query.data.replace(/[^\?]*\?/, "").split("&");
      let size = 20;
      params.forEach((param) => {
        let [key, value] = param.split("=");
        if (key === "pageSize") {
          size = value;
        }
      });

      return {
        [`data|${size}`]: [
          {
            name: Random.ctitle(),
            key: Random.id(),
            age: Random.integer(),
            address: Random.ctitle(),
            tags: [Random.word()]
          }
        ],
        total: 100,
        code: 200
      };
    }
  }
];
