import { getUrlParams } from "@/tool/index";

const state = {
  params: getUrlParams()
};

export const meta = {
  namespaced: true,
  state
};
