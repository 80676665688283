import { render, staticRenderFns } from "./inject-wrap.vue?vue&type=template&id=1b7ed270&scoped=true&"
import script from "./inject-wrap.vue?vue&type=script&lang=js&"
export * from "./inject-wrap.vue?vue&type=script&lang=js&"
import style0 from "./inject-wrap.vue?vue&type=style&index=0&id=1b7ed270&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b7ed270",
  null
  
)

export default component.exports