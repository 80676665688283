import request from "tsl-axios";
import { getCookie } from "../tool";

request.config({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 5000
});

request.instance.interceptors.request.use(
  function (config) {
    // 判断session是否存在
    const auth = config.headers["X-Portal-Session"];
    if (!auth) {
      const session = getCookie("SESSION");
      if (session) {
        config.headers["X-Portal-Session"] = session;
      }
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

request.instance.interceptors.response.use(
  function (response) {
    if (response.status !== 200) {
      console.error("网络错误....");
      return Promise.reject(response);
    } else {
      // 下载文件用
      if (response.request && response.request.responseType === "blob") {
        return response;
      }
      // code 数字化转换
      response.data.code = Number(response.data.code) || response.data.code;
      // 统一错误处理
      if (!1 && response.data.code !== 200) {
        //正式接口对接的时候去掉!1
        if (response.data && response.data.msg) {
          // Notify({ type: "danger", message: response.data.msg });
        }
        return Promise.reject(response.data);
      } else {
        return response.data;
      }
    }
  },
  function (error) {
    return Promise.reject(error);
  }
);
