import Vue from "vue";
import Content from "@/components/content/Index";
import slideCheck from "@/components/slide-check/Index";
import commonHeader from "@/components/common-header/Index";
import popupHint from "@/components/popup-hint/Index";

Vue.component("login-content", Content);
Vue.component("slide-check", slideCheck);
Vue.component("common-header", commonHeader);
Vue.component("popup-hint", popupHint);
