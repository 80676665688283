import request from "tsl-axios";

/**
 * 用户注册-预存
 * @returns {*}
 */
export function register(params) {
  return request.post("/userInfo/register", params).then((response) => {
    return response;
  });
}
/**
 * 用户注册提交
 * @returns {*}
 */
export function registerSubmit(params) {
  return request.post("/userInfo/register/submit", params).then((response) => {
    return response;
  });
}
/**
 * 忘记密码-校验
 * @returns {*}
 */
export function forgetPwd(params) {
  return request.post("/userInfo/forgetPwd", params).then((response) => {
    return response;
  });
}
/**
 * 忘记密码-提交
 * @returns {*}
 */
export function forgetPwdSubmit(params) {
  return request.post("/userInfo/forgetPwd/submit", params).then((response) => {
    return response;
  });
}
/**
 * 用户注册(手机号)检测
 * @returns {*}
 */
export function checkPhoneRegister(params) {
  return request.get("/userInfo/check/phone", params).then((response) => {
    return response;
  });
}
/**
 * 登录检测
 * @returns {*}
 */
export function loginCheckApi() {
  return request.get("/session/check").then((response) => {
    return response;
  });
}
/**
 * 用户信息
 * @returns {*}
 */
export function userInfoApi() {
  return request.get("/userInfo/getInfo").then((response) => {
    return response;
  });
}
/**
 * 登录 手机+密码
 * @returns {*}
 */
export function loginPhone(params) {
  return request
    .post("/phonePassword", params, {
      // 请求数据转为 formdata 格式
      transformRequest: [
        function (data) {
          let ret = "";
          for (const item in data) {
            ret += encodeURIComponent(item) + "=" + encodeURIComponent(data[item]) + "&";
          }
          return ret;
        }
      ],
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
    .then((response) => {
      return response;
    })
    .catch((error) => console.log(error));
}
/**
 * 登录 用户名+密码
 * @returns {*}
 */
export function loginUsername(params) {
  return request
    .post("/usernamePassword", params, {
      // 请求数据转为 formdata 格式
      transformRequest: [
        function (data) {
          let ret = "";
          for (const item in data) {
            ret += encodeURIComponent(item) + "=" + encodeURIComponent(data[item]) + "&";
          }
          return ret;
        }
      ],
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
    .then((response) => {
      return response;
    })
    .catch((error) => console.log(error));
}
/**
 * 登录 手机+短信
 * @returns {*}
 */
export function loginSms(params) {
  return request
    .post("/phoneSms", params, {
      transformRequest: [
        function (data) {
          let ret = "";
          for (const item in data) {
            ret += encodeURIComponent(item) + "=" + encodeURIComponent(data[item]) + "&";
          }
          return ret;
        }
      ],
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
    .then((response) => {
      return response;
    })
    .catch((error) => console.log(error));
}
