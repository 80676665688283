/*
 * @Description:mock输出
 * @Version: feat 1.0
 * @Date: 2021-01-20 14:26:06
 * @LastEditTime: 2021-01-20 18:15:31
 */
import Mock from "mockjs";

import table from "./table";

const mocks = [...table];

function param2Obj(url) {
  return {
    data: url,
    msg: "mock param2Obj function 待实现， 请查看：mock/index.js 中的param2Obj方法"
  };
}

// for front mock
// please use it cautiously, it will redefine XMLHttpRequest,
// which will cause many of your third-party libraries to be invalidated(like progress event).
export function mockXHR() {
  // mock patch
  // https://github.com/nuysoft/Mock/issues/300
  Mock.XHR.prototype.proxy_send = Mock.XHR.prototype.send;
  Mock.XHR.prototype.send = function (...args) {
    if (this.custom.xhr) {
      this.custom.xhr.withCredentials = this.withCredentials || false;

      if (this.responseType) {
        this.custom.xhr.responseType = this.responseType;
      }
    }
    this.proxy_send(...args);
  };

  function XHR2ExpressReqWrap(respond) {
    return function (options) {
      let result = null;
      if (respond instanceof Function) {
        const { body, type, url } = options;
        // https://expressjs.com/en/4x/api.html#req
        result = respond({
          method: type,
          body: JSON.parse(body),
          query: param2Obj(url)
        });
      } else {
        result = respond;
      }
      return Mock.mock(result);
    };
  }

  for (const i of mocks) {
    Mock.mock(new RegExp(i.url), i.type || "get", XHR2ExpressReqWrap(i.response));
  }
}

export default mocks;
