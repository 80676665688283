<template>
  <div class="jc-component__range">
    <div class="jc-range" :class="rangeStatus ? 'success' : ''">
      <span @mousedown="rangeMove" :class="rangeStatus ? 'success' : 'start'">
        <img :src="rangeStatus ? successIcon : startIcon" />
      </span>
      <p class="jc-range-text">{{ rangeStatus ? successText : startText }}</p>
      <div class="jc-range-success" id="rangeSuccess"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    // 开始的函数
    startFun: {
      type: Function
    },
    // 成功之后的函数
    successFun: {
      type: Function
    },
    //成功图标
    successIcon: {
      type: String,
      default: require("@/assets/img/success.png")
    },
    //成功文字
    successText: {
      type: String,
      default: "验证通过"
    },
    //开始的图标
    startIcon: {
      type: String,
      default: require("@/assets/img/start.png")
    },
    //开始的文字
    startText: {
      type: String,
      default: "请按住滑块，向右拖动到最后"
    },
    //失败之后的函数
    errorFun: {
      type: Function
    },
    //或者用值来进行监听
    status: {
      type: String
    }
  },
  data() {
    return {
      disX: 0,
      rangeStatus: false
    };
  },
  methods: {
    //滑块移动
    rangeMove(e) {
      let ele = e.target;
      let rangeSuccess = document.getElementById("rangeSuccess");
      if (ele.localName === "img") {
        ele = ele.parentNode;
      }
      ele.style.transition = "none";
      rangeSuccess.style.transition = "none";
      let startX = e.clientX;
      let eleWidth = ele.offsetWidth;
      let parentWidth = ele.parentElement.offsetWidth;
      let MaxX = parentWidth - eleWidth;
      if (this.rangeStatus) {
        //不运行
        return false;
      }
      // 滑动前校验
      const flag = this.startFun ? this.startFun() : true;
      if (!flag) {
        return false;
      }
      document.onmousemove = (e) => {
        let endX = e.clientX;
        this.disX = endX - startX;
        if (this.disX <= 0) {
          this.disX = 0;
        }
        if (this.disX >= MaxX - eleWidth / 2) {
          this.disX = MaxX;
        }

        ele.style.transform = "translateX(" + this.disX + "px)";
        rangeSuccess.style.width = this.disX + "px";
        e.preventDefault();
      };
      document.onmouseup = () => {
        if (this.disX !== MaxX) {
          ele.style.transition = ".5s all";
          rangeSuccess.style.transition = ".5s all";
          ele.style.transform = "translateX(0)";
          rangeSuccess.style.width = 0;

          //执行成功的函数
          this.errorFun && this.errorFun();
        } else {
          this.rangeStatus = true;
          if (this.status) {
            this.$parent[this.status] = true;
          }
          //执行成功的函数
          this.successFun && this.successFun();
        }
        document.onmousemove = null;
        document.onmouseup = null;
      };
    }
  }
};
</script>
<style lang="less" scoped>
.jc-component__range {
  .jc-range {
    position: relative;
    height: 40px;
    color: @sso-text-darkgray;
    font-size: 14px;
    line-height: 40px;
    text-align: center;
    background-color: @sso-gray;
    transition: 0.6s all;
    user-select: none;
    &.success {
      color: @sso-text-white;
      background-color: @sso-slide;
      pointer-events: none;
    }
    .jc-range-text {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
    }
    .jc-range-success {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      width: 0;
      height: 100%;
      background-color: @sso-slide;
    }
    span {
      position: absolute;
      left: 0;
      z-index: 3;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 100%;
      color: @sso-slide;
      font-size: 12px;
      background-color: @sso-white;
      border: 1px solid @sso-border-main;
      cursor: pointer;
    }
    .start {
      img {
        width: 20px;
      }
    }
    .success {
      img {
        width: 20px;
      }
    }
  }
}
</style>
