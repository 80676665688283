<template>
  <div class="wrapper" :style="customStyle">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState } from "vuex";
const themeEnum = [
  "--theme-color: #5E66F2; --main-btn-disabled-bg-color: #AFBAFA;",
  "--theme-color: #6AD6C7; --main-btn-disabled-bg-color: #B2EFE0;",
  "--theme-color: #688DF5; --main-btn-disabled-bg-color: #B4CEFB;"
];
export default {
  name: "Wrapper",
  mounted() {
    console.log(this.params);
    if (this.params.fn) {
      const paramsUserFn = JSON.parse(decodeURIComponent(this.params.fn));
      this.$store.commit("user/setPermission", paramsUserFn);
    }
    if (this.params.title) {
      const paramsTitle = decodeURIComponent(this.params.title);
      this.$store.commit("user/setTitle", paramsTitle);
    }
  },
  computed: {
    ...mapState({ params: (state) => state.meta.params }),
    customStyle() {
      let styleArr = [];
      let styleObj = {};
      let isEmptyStyle = true;
      if (this.params.style) {
        styleObj = JSON.parse(decodeURIComponent(this.params.style));
        for (const styleItem in styleObj) {
          styleArr.push(styleObj[styleItem]);
        }
        isEmptyStyle = styleArr.every((item) => item === "");
      }
      // 应用传了style参数且不为空字符串
      if (!isEmptyStyle) {
        const otherStyle = `--main-text-color: ${styleObj.mainTextColor || ""};
        --panel-bg-color: ${styleObj.panelBgColor || ""};
        --panel-border: ${styleObj.panelBorder || ""};
        --panel-box-shadow: ${styleObj.panelBoxshadow || ""};
        --title-position: ${styleObj.titlePosition || ""};
        --main-btn-text-color: ${styleObj.mainBtnTextColor || ""};
        --main-btn-border: ${styleObj.mainBtnBorder || ""};
        --sub-btn-bg-color: ${styleObj.subBtnBgColor || ""};
        --sub-btn-text-color: ${styleObj.subBtnTextColor || ""};
        --sub-btn-border: ${styleObj.subBtnBorder || ""};`;

        // 应用传了自定义主题色及禁用色，则默认主题色不生效
        if (styleObj.themeColor && styleObj.mainBtnDisabledBgColor) {
          return (
            `--theme-color: ${styleObj.themeColor || ""};
          --main-btn-disabled-bg-color: ${styleObj.mainBtnDisabledBgColor || ""};` + otherStyle
          );
        }
        // 应用没有传自定义主题色及禁用色，则选中的配套主题色生效
        else if (this.params.theme) {
          return themeEnum[this.params.theme - 1] + otherStyle;
        }
        // 应用没有传自定义主题色及禁用色，也没有传配套主题色，则生成默认廖蓝主题
        else return otherStyle;
      }
      // 应用没有传style参数，但指定了配套主题色
      else if (this.params.theme) {
        return themeEnum[this.params.theme - 1];
      }
      // 用户没有传style也没有传theme，样式采用默认样式
      else return null;
    }
  }
};
</script>
<style scoped lang="less">
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
