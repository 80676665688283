import VueRouter from "vue-router";
import LoginWrap from "@/views/common/login-wrap";
import UserWrap from "@/views/common/user-wrap";
import InjectWrap from "@/views/common/inject-wrap";
import store from "@/store";

const routes = [
  {
    path: "/",
    component: LoginWrap,
    redirect: "/login",
    children: [
      {
        path: "/login",
        redirect: "/login/password",
        component: () => import("@/views/login"),
        children: [
          {
            path: "/login/password",
            component: () => import("@/views/login/module/Password")
          },
          {
            path: "/login/phone",
            component: () => import("@/views/login/module/Phone")
          }
        ]
      },
      {
        path: "/register",
        component: () => import("@/views/register")
      },
      {
        path: "/findPassword",
        component: () => import("@/views/findPassword")
      }
    ]
  },
  {
    path: "/inject",
    redirect: "/inject/login",
    component: InjectWrap,
    children: [
      {
        path: "login",
        redirect: "/inject/login/password",
        component: () => import("@/views/login"),
        children: [
          {
            path: "password",
            component: () => import("@/views/login/module/Password"),
            meta: {
              inject: true
            }
          },
          {
            path: "phone",
            component: () => import("@/views/login/module/Phone"),
            meta: {
              inject: true
            }
          }
        ]
      },
      {
        path: "register",
        component: () => import("@/views/register"),
        meta: {
          inject: true
        }
      },
      {
        path: "findPassword",
        component: () => import("@/views/findPassword"),
        meta: {
          inject: true
        }
      }
    ]
  },
  {
    path: "/user",
    component: UserWrap,
    redirect: "/user/basic",
    children: [
      {
        path: "basic",
        component: () => import("@/views/user/basic.vue")
      },
      {
        path: "phone",
        component: () => import("@/views/user/phone.vue")
      },
      {
        path: "email",
        component: () => import("@/views/user/email.vue")
      },
      {
        path: "account",
        component: () => import("@/views/user/account.vue")
      }
    ]
  },
  // {
  //   path: "/protocol",
  //   redirect: "/protocol/user",
  //   component: () => import("@/views/protocol"),
  //   children: [
  //     {
  //       path: "user",
  //       component: () => import("@/views/protocol/module/User")
  //     },
  //     {
  //       path: "privacy",
  //       component: () => import("@/views/protocol/module/Privacy")
  //     }
  //   ]
  // },
  {
    path: "*",
    redirect: "/"
  }
];

const router = new VueRouter({
  routes,
  mode: "history",
  base: window["__POWERED_BY_QIANKUN__"] ? `/${process.env.VUE_APP_NAME}` : "/"
});

router.beforeEach(async (to, from, next) => {
  const res = await store.dispatch("user/checkUser");
  console.log(res);
  if (!res) {
    if (/^\/user/.test(to.fullPath)) {
      next("/login");
    } else {
      next();
    }
  } else if (!/^\/user/.test(to.fullPath)) {
    next("/user");
  } else {
    next();
  }
});

export function initRouter() {
  return router;
}
