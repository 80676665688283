<template>
  <div class="hint-wrap" :style="{ top: `${top}px`, left: `${left}px` }">
    <slot name="hint"></slot>
    <span class="tri"></span>
  </div>
</template>

<script>
export default {
  props: {
    top: Number,
    left: {
      type: Number,
      default: 454
    }
  }
};
</script>

<style lang="less" scoped>
.hint-wrap {
  position: absolute;
  width: 335px;
  padding: 20px 20px 20px 30px;
  background: @sso-white;
  box-shadow: 0 8px 24px 4px rgba(172, 172, 174, 0.3);
  .tri {
    position: absolute;
    top: 8px;
    left: -8px;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-right: 10px solid @sso-white;
    border-bottom: 8px solid transparent;
  }
  .hint-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .hint-text {
      display: block;
      color: @sso-text-darkgray;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }
    .svg-icon {
      margin-right: 6px;
      margin-left: -18px;
      font-size: 12px;
    }
  }
}
</style>
