export default {
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    encryptPhone() {
      const phone = this.$store.state.user.user.mobile;
      const encryptPhoneReg = /^(\d{3})\d{4}(\d{4})$/;
      return phone.replace(encryptPhoneReg, "$1****$2");
    },
    encryptEmail() {
      const email = this.$store.state.user.user.email;
      const encryptEmailReg = /(.{2}).*(@.*)/;
      return email.replace(encryptEmailReg, "$1******$2");
    },
    avatarStr() {
      const nickName = this.$store.state.user.user.nickName || "";
      return nickName ? nickName.slice(0, 1) : this.$store.state.user.user.userAccount.slice(0, 1);
    }
  }
};
