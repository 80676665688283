<template>
  <div class="content" :style="styleConfig">
    <div class="title" v-if="$slots.title">
      <slot name="title"></slot>
    </div>
    <div class="title" v-if="header">
      {{ header }}
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "App",
  components: {},
  props: {
    width: {
      type: String,
      default: "440px"
    },
    height: {
      type: String,
      default: "auto"
    },
    header: {
      type: String,
      default: ""
    }
  },
  data() {
    return {};
  },
  computed: {
    styleConfig() {
      return {
        width: this.width,
        height: this.height
      };
    }
  }
};
</script>
