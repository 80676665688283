<template>
  <div class="header" :style="headerStyle">
    <div class="header-l">
      <img :src="require('@/assets/img/logo_text.png')" alt="logo" />
      <span class="scopboder"></span>
      <span>统一登录中心</span>
    </div>
    <slot name="header-r"></slot>
  </div>
</template>

<script>
export default {
  props: {
    headerStyle: Object
  }
};
</script>

<style lang="less" scoped>
@headerH: 64px;
@footerH: 90px;

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: @headerH;
  .header-l {
    display: flex;
    align-items: center;
    margin-left: 32px;
    color: @sso-text-main;
    font-size: 16px;
    line-height: 1;
    .scopboder {
      height: 14px;
      margin: 0 16px;
      border-left: 1px solid @sso-text-gray;
    }
    img {
      width: 190px;
    }
  }
}
</style>
