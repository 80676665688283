/**
 * 防抖
 */
export function _debounce(func, wait) {
  let timerId = null;
  let flag = true;
  return function () {
    clearTimeout(timerId);
    if (flag) {
      func.apply(this, arguments);
      flag = false;
    }
    timerId = setTimeout(() => {
      flag = true;
    }, wait);
  };
}

/**
 * 设置cookie
 */
export function setCookie(key, value, time) {
  const cur = new Date();
  cur.setTime(cur.getTime() + time * 24 * 3600 * 1000);
  document.cookie = `${key}=${encodeURIComponent(value)}expires=${time ? cur.toUTCString() : ""}}`;
}
/**
 * 获取cookie
 */
export function getCookie(name) {
  var prefix = name + "=";
  var start = document.cookie.indexOf(prefix);

  if (start == -1) {
    return null;
  }

  var end = document.cookie.indexOf(";", start + prefix.length);
  if (end == -1) {
    end = document.cookie.length;
  }

  var value = document.cookie.substring(start + prefix.length, end);
  return unescape(value);
}
/**
 * 删除cookie
 */
export function delCookie(key) {
  setCookie(key, "", -1);
}

/**
 * 获取url的参数
 * @returns {{}}
 */
export function getUrlParams() {
  let params = {},
    search = window.location.search.replace(/^[^\?]*\?|#.*$/g, "").split("&");

  for (let i = 0; i < search.length; i++) {
    if (search[i] && search[i].length > 0) {
      let arr = search[i].split("=");
      params[arr[0]] = arr[1];
    }
  }
  return params;
}
