<template>
  <div class="wrapper-container">
    <common-header :headerStyle="headerStyle">
      <template #header-r>
        <a class="logout-btn" href="/logout">退出</a>
      </template>
    </common-header>
    <div class="side-menu">
      <div class="user-info" v-if="user">
        <div class="user-avatar">
          <img v-if="user.headImgUrl" :src="user.headImgUrl" alt="" />
          {{ user.headImgUrl ? "" : avatarStr }}
        </div>
        <div class="user-name">
          <span>{{ user.nickName }}</span>
          <span>{{ user.userAccount }}</span>
        </div>
      </div>
      <ul class="user-menu">
        <li
          v-for="(item, index) in userMenu"
          :key="index"
          @click="activeItem(index)"
          :class="[activeMenuItemIndex === index ? 'active-li' : null]"
        >
          <span><svg-icon class="menu-icon" :icon-class="item.icon"></svg-icon></span>
          <span class="menu-title">{{ item.title }}</span>
        </li>
      </ul>
    </div>
    <div class="user-content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import userMixin from "@/mixins/user-mixin.js";

export default {
  mounted() {
    // 根据url显示侧边栏导航激活项
    const urlArr = window.location.pathname.split("/");
    this.activeMenuItem = urlArr[urlArr.length - 1];
    for (let i = 0; i < this.userMenu.length; i++) {
      if (this.userMenu[i].name === this.activeMenuItem) {
        this.activeMenuItemIndex = i;
      }
    }

    // 获取用户信息
    this.$store.dispatch("user/getUserInfo");
  },
  mixins: [userMixin],
  data() {
    return {
      userMenu: [
        {
          title: "基本信息",
          icon: "basic",
          name: "basic"
        },
        {
          title: "手机号",
          icon: "phone",
          name: "phone"
        },
        {
          title: "邮箱",
          icon: "email",
          name: "email"
        },
        {
          title: "账号密码",
          icon: "account",
          name: "account"
        }
      ],
      headerStyle: {
        borderBottom: "1px solid #E5E6EB"
      },
      activeMenuItem: "",
      activeMenuItemIndex: -1
    };
  },
  methods: {
    activeItem(index) {
      if (this.activeMenuItemIndex === index) return;
      this.activeMenuItemIndex = index;
      this.$router.replace(`/user/${this.userMenu[index].name}`);
    }
  }
};
</script>

<style lang="less" scoped>
@color_border: #e5e6eb;
@color_bg_acitve: #e5e6eb;
@color_txt_main: #1d2129;
@color_txt_sub: #4e5969;
@color_txt_active: #545beb;
@color_icon_main: #818a9b;
@color_icon_active: #545beb;

.wrapper-container {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  .side-menu {
    float: left;
    width: 260px;
    height: calc(100vh - 64px);
    padding: 0 16px;
    background-color: #f7f8fa;
    border-right: 1px solid @color_border;
    .user-menu {
      > li {
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;
        padding-left: 16px;
        border-radius: 3px;
        cursor: pointer;
        .menu-icon {
          margin-right: 8px;
          color: @color_icon_main;
          font-size: 24px;
        }
        .menu-title {
          color: @color_txt_main;
          font-weight: 400;
          font-size: 14px;
        }
      }
      .active-li {
        background-color: @color_bg_acitve;
        .menu-title {
          color: @color_txt_active;
        }
        .menu-icon {
          color: @color_icon_active;
        }
      }
    }
    .user-info {
      display: flex;
      align-items: center;
      height: 108px;
      .user-avatar {
        width: 60px;
        height: 60px;
        margin-right: 10px;
        overflow: hidden;
        color: #fff;
        font-weight: 500;
        font-size: 25px;
        font-family: Roboto-Medium, Roboto;
        line-height: 60px;
        text-align: center;
        background-color: #9baed3;
        border-radius: 30px;
        > img {
          width: 60px;
          height: 60px;
          margin-top: -7px;
        }
      }
      .user-name {
        display: flex;
        flex-direction: column;
        :first-child {
          color: @color_txt_main;
          font-size: 16px;
          line-height: 22px;
        }
        :last-child {
          color: @color_txt_sub;
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
  }
  .user-content {
    float: right;
    width: calc(100vw - 260px);
    height: calc(100vh - 64px);
    padding: 40px 48px 0;
    background-color: #fff;
  }
  .logout-btn {
    margin-right: 32px;
    color: @color_txt_sub;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
  }
}
</style>
