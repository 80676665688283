import { loginCheckApi, userInfoApi } from "../api/user";

const state = {
  session: "",
  user: null,
  permission: {
    remember: false,
    phone: false,
    forget_password: false
  },
  title: "账号密码登录"
};

const getters = {
  user(state) {
    if (!state.user) {
      try {
        const user = localStorage.getItem("user");
        state.user = JSON.parse(user);
      } catch (e) {
        console.error(e);
      }
    }
    return state.user;
  }
};

const mutations = {
  setUser(state, user) {
    state.user = user;
    localStorage.setItem("user", JSON.stringify(user));
  },

  setPermission(state, permission) {
    state.permission = permission;
    localStorage.setItem("persmission", JSON.stringify(permission));
  },

  setTitle(state, title) {
    state.title = title;
    localStorage.setItem("title", title);
  }
};

const actions = {
  // 获取用户信息 session
  async checkUser() {
    const res = await loginCheckApi();
    if (res.success) {
      return true;
    } else {
      return false;
    }
  },
  async getUserInfo(context) {
    const res = await userInfoApi();
    if (res.success) {
      context.commit("setUser", res.data);
    }
  }
};

export const user = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
