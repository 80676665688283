<template>
  <div class="full-parent wrapper">
    <common-header />
    <div class="article">
      <router-view></router-view>
    </div>
    <div class="footer">
      <p>©Terminus Technologies Co., Ltd. All Rights Reserved.</p>
      <p>
        <a href="https://beian.miit.gov.cn/#/Integrated/index">渝ICP备14002581号</a> |
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010302002747"
          >渝公网安备 50010302002747号</a
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Wrapper",
  mounted() {
    this.$store.commit("user/setPermission", this.userFn);
  },
  data() {
    return {
      userFn: {
        remember: true,
        phone: true,
        forget_password: true
      }
    };
  }
};
</script>

<style scoped lang="less">
@headerH: 64px;
@footerH: 90px;
.wrapper {
  position: relative;
  overflow: auto;
  background: @sso-white url("../../assets/img/background-login.png") no-repeat center center;
  background-size: cover;
  .article {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: calc(100% - 194px);
    margin: 20px 0;
  }
  .footer {
    z-index: 3;
    width: 100%;
    height: @footerH;
    color: @sso-text-darkgray;
    text-align: center;
    p {
      margin-top: 10px;
      font-size: 12px;
      a {
        color: @sso-text-darkgray;
        font-size: 12px;
      }
    }
  }
}
</style>
