/*
 * @Descripttion :
 * @Author       : Harry
 * @Date         : 2021-07-08 12:53:16
 * @LastEditors  : Harry
 * @LastEditTime : 2021-07-08 12:57:04
 */
import Vue from "vue";
import SvgIcon from "@/components/svg-icon/SvgIcon"; // svg component

// register globally
Vue.component("svg-icon", SvgIcon);

const req = require.context("./svg", false, /\.svg$/);
const requireAll = (requireContext) => requireContext.keys().map(requireContext);
const re = requireAll(req);
console.log(re);
